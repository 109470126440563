export const primary = '#F16E76';
export const secondary = '#E6548A';
export const navy = '#1B232E';
export const slate = '#222B38';
export const grey = '#70757D';
export const greyLight = '#DEDEDE';

export const background = '#E5E5E5';

export const gradient = `linear-gradient(90deg, ${primary} 0%, ${secondary} 100%)`
export const boxShadow = '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)';
