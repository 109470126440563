import { red } from '@material-ui/core/colors';
import { createMuiTheme, fade } from '@material-ui/core/styles';
import { navy, slate } from '../util/theme';

// A custom theme for this app
const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1280,
            xl: 1920,
        }
    },
    palette: {
        primary: {
            main: "#FF5B9C"
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join('.')
    },
    overrides: {}
});

theme.overrides = {};
theme.props = {};

theme.overrides.MuiButton = {
    label: {
        textTransform: 'none'
    }
};

theme.overrides.MuiStepper = {
    root: {
        background: 'transparent',
        padding: 0
    }
};

theme.overrides.MuiFormLabel = {
    root: {
        background: 'transparent',
        padding: 0,
        '&$focused': {
            color: navy
        }
    }
};

theme.overrides.MuiStepLabel = {
    alternativeLabel: {
        marginTop: '0.3rem !important'
    }
};

theme.overrides.MuiStepConnector = {
    alternativeLabel: {
        top: '20px'
    }
};

theme.overrides.MuiStepIcon = {
    text: {
        fill: '#fff'
    }
};

theme.overrides.MuiTextField = {
    root: {
        borderRadius: 8,
    },
};

theme.overrides.MuiCheckbox = {
    root: {
        color: slate,
        '&$checked': {
            color: slate,
        },
    },
    checked: {},
};

export default theme;
