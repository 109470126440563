import React from 'react';
import '../styles/app.less';
import theme from '../styles/material-theme';
import { ThemeProvider } from '@material-ui/core/styles';

const Global: React.FC = (props) => {
    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    );
}

export default Global;
