// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-add-product-tsx": () => import("./../../../src/pages/admin/add-product.tsx" /* webpackChunkName: "component---src-pages-admin-add-product-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-facebook-tsx": () => import("./../../../src/pages/facebook.tsx" /* webpackChunkName: "component---src-pages-facebook-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-google-tsx": () => import("./../../../src/pages/google.tsx" /* webpackChunkName: "component---src-pages-google-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-my-products-tsx": () => import("./../../../src/pages/my-products.tsx" /* webpackChunkName: "component---src-pages-my-products-tsx" */),
  "component---src-pages-requests-tsx": () => import("./../../../src/pages/requests.tsx" /* webpackChunkName: "component---src-pages-requests-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-sheets-tsx": () => import("./../../../src/pages/sheets.tsx" /* webpackChunkName: "component---src-pages-sheets-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-unsubscribe-tsx": () => import("./../../../src/pages/unsubscribe.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/Legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

